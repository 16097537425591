import Vue from "vue";
import vuetify from "../vuetify.js";
import depsloader from "../depsloader";
import ContactApp from "../components/contact/ContactApp.vue";

export default function() {
  const element = document.querySelector("#vue-contact");

  if (element) {
    depsloader.load("vue-i18n").then((vueI18n) => {
      Vue.use(vueI18n.VueI18n);

      let vueArgs = {};

      let renderFn = ContactApp;

      if (element.dataset.name) {
        if (element.dataset.name == "ratgeber") {
          renderFn = require("../components/contact/RatgeberApp.vue").default;
        }

        if (element.dataset.name == "testimonial") {
          renderFn = require("../components/contact/TestimonialApp.vue").default;
        }
      }

      vueArgs = {...vueArgs, ...{
        el: element,

        components: {
          ContactApp
        },

        vuetify,
        i18n: new vueI18n.VueI18n(vueI18n.options),

        render: h => h(renderFn)
      }};

      new Vue(vueArgs)
    });
  }
}
