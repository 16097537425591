<template>
<div v-if="visible" class="contact__wrapper testimonials-form">
<v-app>
  <contact-form :name="name" buttonText="Bewertung abgeben" :requiredFields="requiredFields">
    <h3 class="text-center">Kundenstimme abgeben</h3>

    <testimonial-form></testimonial-form>
    <template slot="disclaimer">
      Mit der Erfassung, Speicherung, Verwendung und Veröffentlichung meiner Bewertung bin ich einverstanden.
      Hinweis: Sie können Ihre Einwilligung jederzeit widerrufen.
    </template>
  </contact-form>
</v-app>
</div>
</template>

<script>
import ContactForm from "./ContactForm.vue";
import TestimonialForm from "./TestimonialForm.vue";

export default {
  name: "TestimonialApp",

  components: {
    ContactForm,
    TestimonialForm
  },

  data() {
    return {
      name: "testimonials",
      visible: false
    }
  },

  computed: {
    requiredFields() {
      return this.$yeti.formRequiredFields(this.name);
    }
  },

  mounted() {
    const openButton = document.querySelector("a[href='#rate-us']");
    if (openButton) {
      openButton.addEventListener("click", (e) => {
        e.preventDefault();

        this.visible = !this.visible;

        if (this.visible) {
          this.$nextTick(() => {
            this.$el.scrollIntoView();
          });
        }
      });
    }
  }
};
</script>
